<template>

<div class="pagewrap modify-search">
    <div v-if="!pageloader">
      <div class="container py-3">
      <div class="row">
      <div class="col-12 col-md-9">
         <div
              class="mt-3 p-0"
              v-for="(res, index) of roundresult"
              :key="index"
            >
              <div class="hoveredCard">
                <v-card rounded="0" style="padding: 20px 0px">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <h4 class="pt-1 ps-3">Trip Details</h4>
                    </div>
                  </div>
                  <div class="row m-1">
                    <div class="col-lg-12">
                      <div class="mt-2">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 d-flex align-center">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    <!-- {{ getlogo(res.Depature) }} -->
                                    <v-img :src="getlogo(res.Depature)"></v-img>
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Depature)
                                      }}</span>
                                      <br />
                                      <span
                                        v-for="(result, index) of res.Depature"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}
                                        </span>

                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Depature.length - 1"
                                        >
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Depature)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                      >{{ getTime(res.Depature) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500">{{
                                      getAirportCode(res.Depature)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getDepAirportName(res.Depature)
                                      }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Depature)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                      >{{ getArrivalTime(res.Depature) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500"
                                      >{{ getArrivalCode(res.Depature) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getArrAirportName(res.Depature)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Depature) }} {{ getDepStops(res.Depature) > 1 ? "Stops" : "Stop" }}
                                </p>
                                <div class="d-flex flex-md-column flex-row justify-center align-center">
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Depature"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0">({{ data.Departure.AirportCode }})</span>
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Depaturejourney.Time
                                  }}</span
                                  ><br />
                                  <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ depfligtTime(res.Depature) }})</span
                                  >
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>

                        <br />

                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 d-flex align-center">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    <v-img :src="getlogo1(res.Depature)"></v-img>
                                    <span
                                      v-for="(result, index) of logo1"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Return)
                                      }}</span>
                                      <br />
                                      <span
                                        v-for="(result, index) of res.Return"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal"
                                          >{{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}</span
                                        >
                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Return.length - 1"
                                        >
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getreturnDate(res.Return)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                      >{{ getreturnTime(res.Return) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500">
                                      {{
                                        getreturnAirportCode(res.Return)
                                      }}</span
                                    >
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getArrAirportName(res.Depature)
                                      }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getreturnArrivaldate(res.Return)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                      >{{
                                        getreturnArrivalTime(res.Return)
                                      }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500">
                                      {{ getreturnArrivalcode(res.Return) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <!-- kkkk -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getDepAirportName(res.Depature)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getReturnStops(res.Return) }} {{ getReturnStops(res.Return) > 1 ? "Stops" : "Stop" }}
                                </p>
                                <div
                                  class="d-flex flex-md-column flex-row justify-center align-center"
                                >
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Return"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0"
                                      >({{ data.Departure.AirportCode }})</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Returnjourney.Time
                                  }}</span
                                  ><br />
                                  <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ retfligtTime(res.Return) }})</span
                                  >
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>

            <div
              class="mt-3 p-0"
              v-for="(res, index) of onewayresulted"
              :key="index"
            >
              <div class="hoveredCard">
                <v-card rounded="0" style="padding: 20px 0px">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <h4 class="pt-1 ps-3">Trip Details</h4>
                    </div>
                  </div>
                  <div class="row m-1">
                    <div class="col-lg-12">
                      <div class="mt-2">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 d-flex align-center">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    <v-img :src="getlogo(res.Depature)"></v-img>
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Depature)
                                      }}</span>
                                      <br />
                                      <span
                                        v-for="(result, index) of res.Depature"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}
                                        </span>

                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Depature.length - 1"
                                        >
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Depature)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                      >{{ getTime(res.Depature) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500">{{
                                      getAirportCode(res.Depature)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getDepAirportName(res.Depature)
                                      }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Depature)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                      >{{ getArrivalTime(res.Depature) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500"
                                      >{{ getArrivalCode(res.Depature) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{
                                        getArrAirportName(res.Depature)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Depature) }} {{ getDepStops(res.Depature) > 1 ? "Stops" : "Stop" }}
                                </p>
                                <div
                                  class="d-flex flex-md-column flex-row justify-center align-center"
                                >
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Depature"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0"
                                      >({{ data.Departure.AirportCode }})</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Depaturejourney.Time
                                  }}</span
                                  ><br />
                                  <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ depfligtTime(res.Depature) }})</span
                                  >
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>

            <div
              class="mt-3 p-0"
              v-for="(res, index) of multiresult"
              :key="index"
            >
              <div class="hoveredCard">
                <v-card rounded="0" style="padding: 20px 0px">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <h4 class="pt-1 ps-3">Trip Details</h4>
                    </div>
                  </div>
                  <div class="row m-1">
                    <div class="col-lg-12">
                      <div class="mt-2">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 d-flex align-center">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    <v-img :src="getlogo(res.Flight1)"></v-img>
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Flight1)
                                      }}</span>
                                      <br />
                                      <span
                                        v-for="(result, index) of res.Flight1"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}
                                        </span>

                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Flight1.length - 1"
                                        >
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Flight1)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                      >{{ getTime(res.Flight1) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500">{{
                                      getAirportCode(res.Flight1)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{ getDepAirportName(res.Flight1) }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Flight1)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                      >{{ getArrivalTime(res.Flight1) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500"
                                      >{{ getArrivalCode(res.Flight1) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{ getArrAirportName(res.Flight1) }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Flight1) }} {{ getDepStops(res.Flight1) > 1 ? "Stops" : "Stop"}}
                                </p>
                                <div
                                  class="d-flex flex-md-column flex-row justify-center align-center"
                                >
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Flight1"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0"
                                      >({{ data.Departure.AirportCode }})</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Flight1JourneyTime.Time
                                  }}</span
                                  ><br />
                                  <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ depfligtTime(res.Flight1) }})</span
                                  >
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div class="mt-2">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 d-flex align-center">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    <v-img :src="getlogo(res.Flight2)"></v-img>
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Flight2)
                                      }}</span>
                                      <br />
                                      <span
                                        v-for="(result, index) of res.Flight2"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}
                                        </span>

                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Flight2.length - 1"
                                        >
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Flight2)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                      >{{ getTime(res.Flight2) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500">{{
                                      getAirportCode(res.Flight2)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{ getDepAirportName(res.Flight2) }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Flight2)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                    >
                                      {{ getArrivalTime(res.Flight2) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500"
                                      >{{ getArrivalCode(res.Flight2) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{ getArrAirportName(res.Flight2) }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Flight2) }} {{ getDepStops(res.Flight2) > 1 ? "Stops" : "Stop" }}
                                </p>
                                <div
                                  class="d-flex flex-md-column flex-row justify-center align-center"
                                >
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Flight2"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0"
                                      >({{ data.Departure.AirportCode }})</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Flight2JourneyTime.Time
                                  }}</span
                                  ><br />
                                  <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ depfligtTime(res.Flight2) }})</span
                                  >
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div class="mt-2" v-if="res.Flight3 ? true : false">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 d-flex align-center">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    <v-img :src="getlogo(res.Flight3)"></v-img>
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Flight3)
                                      }}</span>
                                      <br />
                                      <span
                                        v-for="(result, index) of res.Flight3"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}
                                        </span>

                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Flight3.length - 1"
                                        >
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Flight3)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                      >{{ getTime(res.Flight3) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500">{{
                                      getAirportCode(res.Flight3)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{ getDepAirportName(res.Flight3) }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Flight3)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                    >
                                      {{ getArrivalTime(res.Flight3) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500"
                                      >{{ getArrivalCode(res.Flight3) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{ getArrAirportName(res.Flight3) }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Flight3) }} {{ getDepStops(res.Flight3) > 1 ? "Stops" : "Stop" }}
                                </p>
                                <div
                                  class="d-flex flex-md-column flex-row justify-center align-center"
                                >
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Flight3"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0"
                                      >({{ data.Departure.AirportCode }})</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Flight3JourneyTime.Time
                                  }}</span
                                  ><br />
                                  <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ depfligtTime(res.Flight3) }})</span
                                  >
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div class="mt-2" v-if="res.Flight4 ? true : false">
                        <div>
                          <v-card style="overflow: unset; padding: 1px">
                            <div class="row mt-2 mb-2 d-flex align-center">
                              <div class="col-md-3 col-3 mb-3 mb-md-0">
                                <div class="row ms-sm-1 ms-0 w-100">
                                  <div class="col-md-4 col-12">
                                    <v-img :src="getlogo(res.Flight4)"></v-img>
                                    <span
                                      v-for="(result, index) of logo"
                                      :key="index"
                                    >
                                      <span v-if="index == 0">
                                        <v-img
                                          class="airline-logo"
                                          :src="result"
                                          width="40px"
                                        ></v-img>
                                      </span>
                                      <span v-if="index > 0">
                                        <v-img
                                          :src="result"
                                          width="20px"
                                        ></v-img>
                                      </span>
                                    </span>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <p class="m-0">
                                      <span class="f-size-14 air-name">{{
                                        getname(res.Flight4)
                                      }}</span>
                                      <br />
                                      <span
                                        v-for="(result, index) of res.Flight4"
                                        :key="result"
                                      >
                                        <span class="f-size-9 air-id fw-normal">
                                          {{
                                            result.MarketingCarrier.AirlineID
                                          }}-{{
                                            result.MarketingCarrier.FlightNumber
                                          }}
                                        </span>

                                        <span
                                          class="f-size-9"
                                          v-if="index < res.Flight4.length - 1"
                                        >
                                          /
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 col-9">
                                <div class="row d-flex w-100">
                                  <div class="col-md-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getDate(res.Flight4)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                      >{{ getTime(res.Flight4) }}</span
                                    >
                                  </div>
                                  <div
                                    class="col-md-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500">{{
                                      getAirportCode(res.Flight4)
                                    }}</span>
                                    <!-- <span class="f-size-16">
                                      ({{ fromname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{ getDepAirportName(res.Flight4) }}</span
                                    >
                                  </div>
                                </div>

                                <div class="row d-flex w-100">
                                  <div class="col-sm-3 col-4 p-0 airline-date">
                                    <span class="f-size-11">{{
                                      getArrivaldate(res.Flight4)
                                    }}</span>
                                    <span
                                      class="f-size-16"
                                      style="margin-left: 5px"
                                    >
                                      {{ getArrivalTime(res.Flight4) }}</span
                                    >
                                  </div>

                                  <div
                                    class="col-sm-9 col-8 p-0 aircode-name ps-md-2 ps-0 text-truncate"
                                    style="margin-bottom: 4px"
                                  >
                                    <span class="f-size-16 fw-500"
                                      >{{ getArrivalCode(res.Flight4) }}
                                    </span>
                                    <!-- <span class="f-size-16">
                                      ({{ toname }})</span> -->
                                    <span class="f-size-13"
                                      >,
                                      {{ getArrAirportName(res.Flight4) }}</span
                                    >
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-1 col-3 ps-3">
                                <p class="f-size-12 text-center m-0">
                                  {{ getDepStops(res.Flight4) }} {{ getDepStops(res.Flight4) > 1 ? "Stops" : "Stop" }}
                                </p>
                                <div
                                  class="d-flex flex-md-column flex-row justify-center align-center"
                                >
                                  <span
                                    class="f-size-9"
                                    v-for="(data, index) of res.Flight4"
                                    :key="index"
                                  >
                                    <span v-if="index !== 0"
                                      >({{ data.Departure.AirportCode }})</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-2 col-4 d-flex justify-md-center"
                              >
                                <div class="Timing-section">
                                  <v-icon size="18">mdi-clock-outline </v-icon>
                                  <span class="ms-1 f-size-14">{{
                                    res.Flight4JourneyTime.Time
                                  }}</span
                                  ><br />
                                  <span
                                    class="f-size-12"
                                    style="line-height: 24px"
                                    >(Flight
                                    {{ depfligtTime(res.Flight4) }})</span
                                  >
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>

        <div class="mt-3">
          <div class="d-lg-flex d-none justify-content-between align-items-start mb-3">
            <div class="fw-bold" style="color:white">Request Your Seats (Optional)</div>
          </div>

          <div class="col-12">
            <div class="theme-box-shadow theme-border-radius bg-white mb-3 p-3 pb-0">
            
              <div v-if="totalSeat.length > 0" class="pb-3 pt-1">
                <div class="mt-1">
                  <div class="d-flex flex-wrap">
                    <div
                      class="pe-2 mt-1"
                      v-for="(data, index) in totalSeat"
                      :key="index"
                    >
                      <v-btn
                        variant="outlined"
                        :class="
                          flightSelect == index ? 'tru-flight' : 'tru-flightfalse'
                        "
                        @click="selectdata(data, index)"
                      >
                        <div>{{ data.origin }}-{{ data.destination }}</div>
                      </v-btn>
                    </div>
                  </div>
                
                  <!-- <div class="d-flex flex-wrap mt-2">
                    <div
                      class="pe-2 mt-1"
                      v-for="(data, index) of totalpassanger"
                      :key="index"
                    >
                      <v-btn
                        style="font-size: 13px"
                        variant="outlined"
                        @click="totalpassData(data, index)"
                        :class="passSelect == index ? 'tru-pass' : ''"
                      >
                        Passenger{{ index + 1 }}</v-btn
                      >
                    </div>
                  </div> -->
                
                <div style="overflow-x: scroll">
                  <table>
                    <thead>
                      <tr>
                        <th>Passenger Details</th>
                      </tr>
                    </thead>
                        
                        <!-- <div  v-for="(data, index) in totalSeat" :key="index">
                        <div style="visibility: hidden;">
                        flight{{index+1}}
                        </div>
                        </div> -->
                      
                      <tr v-for="(data, index) of totalpassanger" :key="index">
                        {{console.log(data,'ppp00000')}}
                        <td v-if="data.type !== 'Infrant'">
                          <div>
                            <span>
                              <span class="text-uppercase"
                          style="font-size: 15px;font-weight:500;cursor:pointer;"
                          variant="outlined" :class="{ selected: index === passSelect, not: index !== passSelect }"
                          @click="totalpassData(data, index)"
                         >
                         {{ index + 1 }}. {{ data.name}}</span>
                            </span>
                          </div>
                        </td>
                        <td v-for="(seat, index) of data.seat_number" :key="index">
                          {{ console.log(seat, "seat") }}
                          <div style="font-size: 14px; letter-spacing: 1px">
                            {{ seat == null ? '-' : seat }}
                          </div>
                        </td>
                      </tr>
                  </table>
                </div> 
              
              
              
              
                  <div
                    style="overflow-x: scroll; border: 1px solid lightgrey"
                    class="mt-2 pa-2" v-if="totalSeat[indexData].cabins && totalSeat[indexData].cabins[0] && totalSeat[indexData].cabins[0].columns"
                  >
                    <div
                      v-for="(data, index) in totalSeat[indexData].cabins[0].columns"
                      :key="index"
                      class="d-flex mt-2"
                    >
                      <div class="px-1 text-center">
                        <div style="width: 25px; font-weight: 500">
                          {{ data.column }}
                        </div>
                      </div>
                      <div
                        class="px-1 text-center"
                        v-for="(datas, index1) in totalData[data.column]"
                        :key="index1"
                      >
                        <div>
                          <div
                            :class="{
                              'false-sheet': datas.seats[0].occupied === 'true',
                              'true-sheet': datas.seats[0].occupied === 'false',
                              'tru-icon':
                                datas.seats[0].occupied === 'false' && datas.selectedPass,
                            }"
                            @click="seatSelectdata(datas, index1, data.column)"
                          >
                            <template v-if="datas.selectedPass">
                              <span class="passenger-number" style="color:white;padding:0px 7px;font-size:14px;">{{ datas.selectedPass }}</span>
                            </template>
                            <template v-else>
                              <v-icon
                                :color="datas.seats[0].occupied === 'false' ? 'white' : 'white'"
                                size="18" style= "margin-bottom:3px"
                              >
                                mdi-seat-passenger
                              </v-icon>
                            </template>
                          <v-tooltip activator="parent" location="top" v-if="datas.seats[0].chargeable === 'true' & datas.seats[0].occupied === 'false'">
                            <span>Price: {{ datas.seats[0].currency }} {{ datas.seats[0].price == '-' ? '0.00' : datas.seats[0].price }}</span>
                          </v-tooltip>
                          </div>
                        </div>
                      
                        <div>
                          <div v-if="data.space" style="font-size: 13px">
                            {{ datas.row_number }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div
                  class="d-flex justify-content-between flex-wrap mt-2 py-2"
                  style="border: 1px solid lightgray"
                >
                  <div class="d-flex flex-wrap m-1nt" style="font-size: 13px">
                    <div class="d-flex ms-1">
                      <div class="av-sheet"></div>
                      <div class="mt-1">Available Seat</div>
                    </div>
                    <div class="d-flex ms-2">
                      <div class="oc-sheet"></div>
                      <div class="mt-1">Occupied Seat</div>
                    </div>
                    <div class="d-flex ms-2">
                      <div class="sel-sheet"></div>
                      <div class="mt-1">Selected Seat</div>
                    </div>
                  </div>
                  <div class="pe-2">
                    <span>Total Seat Request Price: <span class="fw-bold">{{ metaCurrency }} {{ totalSeatPrice.toFixed(2) }}</span></span>
                  </div>
                </div>
              
              </div>
              <div v-else class="pb-3 d-flex align-center justify-center">
                <v-progress-circular color="rgb(0, 45, 91)" size="25"
                  indeterminate></v-progress-circular>
              </div>
            </div>
          </div>

          <div class="row d-flex justify-content-between">
            <div class="col-5">
                <!-- <button @click="submitform" type="submit" class="btn btn-effect btn-book px-5">{{ $t("flightInfoContent.contents.continue") }}</button> -->
                <v-btn
                  width="auto"
                  height="40px"
                  class="continue-btn"
                  type="submit"
                  style="color: white;text-transform: capitalize;background-color: rgb(250, 164, 31);
                    font-size:16px;" @click="onContinue">Continue</v-btn>
            </div>
            <div class="col-7 d-flex justify-end">
              <v-btn
                width="auto" height="40px" class="continue-btn" type="submit"
                style="color: white;text-transform: capitalize;background-color: rgb(250, 164, 31);font-size:16px;"
              @click="submitform">Skip for Seat Selection</v-btn>
            </div>
          </div>
        </div>
      </div>
       <div class="col-lg-3 col-md-3" style="margin-bottom: 146px">
          <div class="fare-summery" :style="{ top: topSticky }">
            <v-card
              max-width="450px"
              class="pa-2 m-auto"
              style="border: 1px solid grey"
            >
              <h5>Fare Details</h5>
              <hr />
              
              <div class="d-flex justify-content-between px-1">
                <div>
                  <p class="m-0" style="font-size: 16px; font-weight: 500">
                    Total Price
                  </p>
                </div>
                <div>
                  <p class="tot-price m-0">
                    {{ currency }} {{ totalAmountpassenger }}
                  </p>
                </div>
              </div>

              <div class="row px-1 mt-2">
                <div class="col-md-12">
                  <div class="row justify-content-between">
                    <div class="col-md-6">
                      <span style="font-size: 14px; font-weight: 500"
                        >Passengers</span
                      >
                    </div>
                    <!-- <div class="col-md-4"></div> -->
                    <div class="col-md-6 text-end" style="font-size: 14px">
                      <span v-if="adult > 0">{{ adult }} x Adult</span><br />
                      <span v-if="child > 0">{{ child }} x Child</span><br />
                      <span v-if="inf > 0">{{ inf }} x Lap Infant</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="d-flex justify-content-between">
                <div>
                  <p
                    class="m-0"
                    style="font-size: 14px; font-weight: 500"
                    :class="{
                      'text-decoration-line': activated1,
                      'text-decoration-lineact': !activated1,
                    }"
                  >
                    Fare Upgrade
                  </p>
                </div>
                <div>
                  <p class="tot-price m-0">
                    {{ currency }} {{ fareupgradeData }}
                  </p>
                </div>
              </div> -->
              <!-- <hr />

              <div class="d-flex justify-content-between">
                <div>
                  <p class="m-0" style="font-size: 18px; font-weight: 500">
                    Total amount
                  </p>
                </div>
                <div>
                  <p class="m-0" style="font-size: 18px; font-weight: 500">
                    {{ currency }} {{ totalpriceData }}
                  </p>
                </div>
              </div> -->

              <div class="mt-1 mx-1">
                <hr class="mt-1 mb-2" />
                <div class="d-flex align-center mb-2" style="cursor: pointer">
                  <span class="f-size-14 pe-1" @click="isIcon = !isIcon">
                    <span style="color: 0D6EFD; text-decoration: underline"
                      >Fare Summary</span
                    >
                  </span>

                  <v-icon
                    size="16"
                    class="not-collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-Example"
                    aria-expanded="true"
                    aria-controls="collapse-Example"
                    v-model="isIcon"
                    style="color: 0D6EFD; overflow-anchor: none"
                    >{{
                      isIcon
                        ? "mdi-chevron-up-box-outline"
                        : "mdi-chevron-down-box-outline"
                    }}</v-icon
                  >
                </div>

                <div
                  class="p-1 collapse"
                  :class="{ show: isIcon }"
                  id="collapse-Example"
                >
                  <div class="row">
                    <div class="col-6">
                      Base Price<br />
                      Taxes & Fees
                    </div>
                    <div class="col-6" align="right">
                      {{ currency }} {{ totalbasePassanger }}<br />
                      {{ currency }} {{ totalTaxPassanger }}
                    </div>
                  </div>
                </div>
                <hr class="mt-1 mb-2" />
                <div class="d-flex align-end mb-2">
                  <span
                    class="not-collapsed"
                    style="
                      padding-right: 5px;
                      cursor: pointer;
                      font-size: 14px;
                      letter-spacing: 1px;
                    "
                  >
                    <a
                      class="baggage border-none"
                      style="
                        font-size: 14px;
                        color: rgb(13, 110, 253);
                        text-decoration: underline;
                      "
                    >
                      <span @click="getFareRules()"
                        >Baggage and Cancellation Rules</span
                      >
                    </a>
                    <div class="drawer-section">
                      <div class="modal" v-if="modalTab">
                        <div class="modal-content">
                          <div
                            class="modal-header d-flex flex-row justify-content-between pt-0"
                          >
                            <h5>Baggage and Cancellation Rules</h5>
                            <span class="close-button" @click="closeModal()"
                              >&times;</span
                            >
                          </div>
                          <div class="modal-body">
                            <v-card class="">
                              <v-tabs v-model="tab" align-tabs="center" stacked>
                                <v-tab value="tab-1">
                                  <v-icon>mdi-bag-checked</v-icon>
                                  Baggage
                                </v-tab>
                                <v-tab value="tab-2" class="m-0">
                                  <v-icon>mdi-book-cancel-outline</v-icon>
                                  Cancellation Rules
                                </v-tab>
                              </v-tabs>

                              <v-card-text class="pt-0">
                                <keep-alive>
                                  <v-window v-model="tab">
                                    <div v-if="tab === 'tab-1'">
                                      <h6 class="mt-1">Baggage Rules:</h6>
                                      <div
                                        class="p-2 rounded"
                                        style="
                                          height: 200px;
                                          overflow-y: scroll;
                                        "
                                        v-for="(data, index) of rountresult"
                                        :key="index"
                                      >
                                        <div>
                                          <div
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Depature[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Depature[
                                                data.Depature.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <!-- <div >
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On:</span>
                                              <span
                                                v-if="data.depBaggage"
                                                class="ms-1"
                                              >
                                                {{
                                                  data.depBaggage.PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 + " Piece"
                                                    : 1 + " Piece"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <!-- <div>
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag:</span>
                                              <span
                                                v-if="data.depBaggage"
                                                class="ms-1"
                                              >
                                                {{
                                                  data.depBaggage.PieceAllowance
                                                    .TotalQuantity || 0
                                                }}

                                                {{
                                                  data.depBaggage.PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? "Pieces"
                                                    : "Piece"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <hr v-if="data.Return" />
                                        <div v-if="data.Return" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Depature[
                                                data.Depature.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                            -
                                            {{
                                              data.Depature[0].Departure
                                                .AirportCode
                                            }}
                                          </div>
                                          <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                          <div class="ms-2 mt-2">
                                            <!-- <div >
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On:</span>
                                              <span class="ms-1">
                                                {{
                                                  data.arrBaggage.PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 + " Piece"
                                                    : 1 + " Piece"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-1">
                                            <!-- <div style="color: gray !important">
                                  
                                </div> -->
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag:</span>
                                              <!-- <span class="ms-1"> {{res.arrBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                              <span class="ms-1">
                                                {{
                                                  data.arrBaggage.PieceAllowance
                                                    .TotalQuantity || 0
                                                }}
                                                {{
                                                  data.arrBaggage.PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? "Pieces"
                                                    : "Piece"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div v-if="onewayresult">
                                        <div
                                          class="p-2 rounded"
                                          style="height: 200px"
                                          v-for="(data, index) of onewayresult"
                                          :key="index"
                                        >
                                          <div>
                                            <div
                                              style="
                                                font-size: 15px;
                                                font-weight: 500;
                                              "
                                            >
                                              {{
                                                data.Depature[0].Departure
                                                  .AirportCode
                                              }}
                                              -
                                              {{
                                                data.Depature[
                                                  data.Depature.length - 1
                                                ].Arrival.AirportCode
                                              }}
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <!-- <div >
                                  
                                </div> -->
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-checked
                                                  </v-icon></span
                                                >
                                                <span>Carry-On:</span>
                                                <span
                                                  v-if="data.BaggageData"
                                                  class="ms-1"
                                                >
                                                  {{
                                                    data.BaggageData
                                                      .PieceAllowance
                                                      .TotalQuantity > 0
                                                      ? 1 + " Piece"
                                                      : 1 + " Piece"
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <!-- <div>
                                  
                                </div> -->
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-suitcase
                                                  </v-icon></span
                                                >
                                                <span> Checked Bag:</span>
                                                <span class="ms-1">
                                                  {{
                                                    data.BaggageData
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}

                                                  {{
                                                    data.BaggageData
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? "Pieces"
                                                      : "Piece"
                                                  }}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div v-if="multiresult.length > 0">
                                        <div
                                          class="border p-2 rounded"
                                          style="
                                            height: 200px;
                                            overflow-y: scroll;
                                          "
                                          v-for="(data, index) of multiresult"
                                          :key="index"
                                        >
                                          <div>
                                            <div
                                              style="
                                                font-size: 15px;
                                                font-weight: 500;
                                              "
                                            >
                                              {{
                                                data.Flight1[0].Departure
                                                  .AirportCode
                                              }}
                                              -
                                              {{
                                                data.Flight1[
                                                  data.Flight1.length - 1
                                                ].Arrival.AirportCode
                                              }}
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-checked
                                                  </v-icon></span
                                                >
                                                <span>Carry-On: </span>
                                                <span class="ms-1">{{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 + " Piece"
                                                    : 1 + " Piece"
                                                }}</span>
                                              </div>
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <!-- <div>
                                  
                                </div> -->
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-suitcase
                                                  </v-icon></span
                                                >
                                                <span> Checked Bag: </span>
                                                <span class="ms-1">
                                                  {{
                                                    data.BaggageData1
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}{{
                                                    data.BaggageData1
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? " Pieces"
                                                      : " Piece"
                                                  }}</span
                                                >
                                              </div>
                                            </div>

                                            <div class="ms-2 mt-2">
                                              <!-- <div >
                                  
                                </div> -->
                                            </div>
                                          </div>
                                          <hr v-if="data.Flight2" />
                                          <div v-if="data.Flight2" class="mt-2">
                                            <div
                                              class="mt-1"
                                              style="
                                                font-size: 15px;
                                                font-weight: 500;
                                              "
                                            >
                                              {{
                                                data.Flight2[0].Departure
                                                  .AirportCode
                                              }}
                                              -
                                              {{
                                                data.Flight2[
                                                  data.Flight2.length - 1
                                                ].Arrival.AirportCode
                                              }}
                                            </div>
                                            <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                            <div class="ms-2 mt-2">
                                              <!-- <div >
                                  
                                </div> -->
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-checked
                                                  </v-icon></span
                                                >
                                                <span>Carry-On: </span>
                                                <span class="ms-1">{{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 + " Piece"
                                                    : 1 + " Piece"
                                                }}</span>
                                              </div>
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <!-- <div>
                                  
                                </div> -->
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-suitcase
                                                  </v-icon></span
                                                >
                                                <span> Checked Bag: </span>
                                                <span class="ms-1">
                                                  {{
                                                    data.BaggageData2
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}{{
                                                    data.BaggageData2
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? " Pieces"
                                                      : " Piece"
                                                  }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                          <hr v-if="data.Flight3" />
                                          <div v-if="data.Flight3" class="mt-2">
                                            <div
                                              class="mt-1"
                                              style="
                                                font-size: 15px;
                                                font-weight: 500;
                                              "
                                            >
                                              {{
                                                data.Flight3[0].Departure
                                                  .AirportCode
                                              }}
                                              -
                                              {{
                                                data.Flight3[
                                                  data.Flight3.length - 1
                                                ].Arrival.AirportCode
                                              }}
                                            </div>
                                            <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                            <div class="ms-2 mt-2">
                                              <!-- <div >
                                  
                                </div> -->
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-checked
                                                  </v-icon></span
                                                >
                                                <span>Carry-On: </span>
                                                <span class="ms-1">{{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 + " Piece"
                                                    : 1 + " Piece"
                                                }}</span>
                                              </div>
                                            </div>
                                            <div class="ms-2 mt-2">
                                              <!-- <div>
                                  
                                </div> -->
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-suitcase
                                                  </v-icon></span
                                                >
                                                <span> Checked Bag: </span>
                                                <span class="ms-1">
                                                  {{
                                                    data.BaggageData3
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}{{
                                                    data.BaggageData3
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? " Pieces"
                                                      : " Piece"
                                                  }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                          <hr v-if="data.Flight4" />
                                          <div v-if="data.Flight4" class="mt-2">
                                            <div
                                              class="mt-1"
                                              style="
                                                font-size: 15px;
                                                font-weight: 500;
                                              "
                                            >
                                              {{
                                                data.Flight4[0].Departure
                                                  .AirportCode
                                              }}
                                              -
                                              {{
                                                data.Flight4[
                                                  data.Flight4.length - 1
                                                ].Arrival.AirportCode
                                              }}
                                            </div>
                                            <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                            <div class="ms-2 mt-2">
                                              <!-- <div >
                                  
                                </div> -->
                                              <div
                                                class="d-flex flex-row"
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span>
                                                  <v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-checked
                                                  </v-icon></span
                                                >
                                                <span>Carry-On: </span>
                                                <span class="ms-1">{{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 + " Piece"
                                                    : 1 + " Piece"
                                                }}</span>
                                              </div>
                                            </div>
                                            <div class="d-flex ms-2 mt-2">
                                              <!-- <div>
                                  
                                </div> -->
                                              <div
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 400;
                                                  margin-left: 5px;
                                                "
                                              >
                                                <span
                                                  ><v-icon
                                                    color="gray"
                                                    style="
                                                      color: gray !important;
                                                    "
                                                  >
                                                    mdi-bag-suitcase
                                                  </v-icon></span
                                                >
                                                <span> Checked Bag: </span>
                                                <span class="ms-1">
                                                  {{
                                                    data.BaggageData4
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}{{
                                                    data.BaggageData4
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? " Pieces"
                                                      : " Piece"
                                                  }}</span
                                                >
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-if="tab === 'tab-2'">
                                      <div
                                        class="p-2 cancellationRules"
                                        style="
                                          height: 200px;
                                          overflow-y: scroll;
                                        "
                                      >
                                        <div
                                          v-if="fareLoader"
                                          class="text-center mt-5"
                                          style="margin: auto"
                                        >
                                          Please wait...
                                          <v-progress-circular
                                            color="primary"
                                            class="ms-1"
                                            indeterminate
                                          ></v-progress-circular>
                                        </div>
                                        <div v-else>
                                          <h6 class="mt-2">
                                            Cancellation Rules:
                                          </h6>
                                          <div class="p-2 cancellationRules">
                                            <div
                                              v-html="fareRulesContent"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </v-window>
                                </keep-alive>
                              </v-card-text>
                            </v-card>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>

                <hr class="mt-1 mb-2" />
                <div>
                  <div style="font-size: 16px; font-weight: 500">
                    Passengers
                  </div>

                  <div
                    class="mt-2"
                    v-for="(data, index) of totalPassData"
                    :key="index"
                  >
                    <div
                      style="
                        text-transform: uppercase;
                        font-size: 12px;
                        font-weight: 500;
                      "
                    >
                      {{ data.salutation }} {{ data.firstname }}
                      {{ data.middlename }} {{ data.lastname }}
                    </div>
                  </div>
                </div>

                <!-- <div class="p-1 collapse" :class="{ show: isIcon }" id="collapse-Example">
                  <div class="row">
                    <div class="col-6">
                      Base Price<br />
                      Taxes & Fees
                    </div>
                    <div class="col-6" align="center">
                      {{ currency }} {{ totalbasePassanger }}<br />
                      {{ currency }} {{ totalTaxPassanger }}
                    </div>
                  </div>
                </div> -->

                <!-- <br />
                <p style="font-size: 12px">
                  All fares are quoted in {{ metaCurrency }}. Some airlines may
                  apply additional baggage fees. Your credit/debit card may be billed in
                  multiple transactions, totaling the final price.
                </p>
              </div>

              <p class="font-weight f-size-11">
                By clicking 'Confirm & Book', I agree that I have read and
                accepted the <span class="text-primary">Terms of use</span>.
              </p> -->
              </div>
            </v-card>

            <!-- <div class="mt-2 mb-2 continue-section" align="center">
              <v-btn
                height="40px"
                class="continue-btn-1"
                type="submit"
                style="
                  font-size: 16px;
                  font-weight: 500;
                  color: white;
                  text-transform: capitalize;
                  background-color: #faa41f;
                "
              >
                Continue To Book</v-btn
              >
            </div> -->
          </div>
        </div>
      </div>
      </div>
  </div>
</div>

 {{ console.log(pageloader,'pageloaderrr') }}
  <div v-if="pageloader">
    <pageLoader></pageLoader>
  </div>

  <v-dialog v-model="seatmapError" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-3" style="max-width: 450px;">
        <!-- <h3 class="text-center">{{ $t("errorContent.dialog1.text1") }}</h3> -->
        <h5 class="text-center">Sorry, the seat selection is unavailable. </h5>
        <h6 class="text-center">Please proceed without seat selection, and an automatic seat will be assigned to you. Thank you!</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="continuePayment()" color="rgb(250, 164, 31)">Continue</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog v-model="dialog" max-width="500">
  <v-card>
    <!-- <v-card-title class="text-center">Confirmation</v-card-title> -->
    <v-card-text>Please select all seats before continuing.To proceed to the next Flight.</v-card-text>
    <v-card-actions style="justify-content:center;">
      <!-- <v-btn text @click="cancelSelection" style="background-color:#c62a82;color:white;">Cancel</v-btn> -->
      <v-btn text @click="confirmSelection" style="background-color:rgb(250, 164, 31);color:white;">OK</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<v-dialog v-model="dialog2" persistent>
  <div class="d-flex justify-center">
    <v-card class="p-3" style="max-width: 450px;">
      <h5 class="text-center">Seat Selection for this Flight is not available.</h5>
      <h6 class="text-center">Please Contact Cutomer Care after completing the Booking.</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="dialog2 = false" style="background-color:rgb(250, 164, 31);color:white;">OK</v-btn>
        </div>
    </v-card>
  </div>
</v-dialog>

</template>

<script>
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
import axios from "axios";
import pageLoader from "@/components/dataLoder.vue";
import moment from "moment";
export default{
    components: {
    pageLoader,
  },
    data(){
    return{
      portal_id:"",
      seatmapApi:"",
      pageloader:true,
      totalSeat: [],
      totalData: [], 
      totalpassanger:[], 
      seatSelect:[],
      passSelect:0,
      flightSelect:0,
      flightSelectionStatus: {},
      seatCharge:false,
      singlePass:false,
      indexData: 0,
      local_id :"",
      getTotalData:"",
      routerData:"",
      dialog2:false,
      seatmapError:false,
      getroutequery:"",
      pointofsale:"",
      topSticky: "10%",
      totalSeatPrice:0.00,
      selectedFlights : {},
      selectedFlights1 : {},
      seatCurrency:"",
      totalAmountpassenger: [],
      currency: "",
      totalTaxPassanger: [],
      totalbasePassanger: [],
      metaCurrency: "",
      displayCurrency: "",
      onewayresult: [],
      roundresult: [],
      rountresult: [],
      multiresult: [],
      paxcontact: [],
      adult: "",
      child: "",
      inf: "",
      fareRulesApi:"",
      fareRulesContent: "",
      tab: null,
      dataloader: true,
      adultLength: "",
      childLength: "",
      infantLength: "",
      adultDetailData: [],
      childDetailData: [],
      infDetailData: [],
      totalPassData: [],
      passDeatail: [],
      dialog:false,
      nextFlightCallback: null,
      passengerCount:0,
      sameLogoData: [],
      sameLogoData1: [],
      sameLogoDataMulti: [],
      sameLogoDataMulti1: [],
      sameLogoDataMulti2: [],
      sameLogoDataMulti3: [],
      segmentList: [],
    }
    
},
unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
methods: {
  depdate(data) {
    return moment(data[0].Departure.Date).format("ddd, DD MMM");
  },
  getdepNameMulti(data) {
    return data.from;
  },
  getarrNameMulti(data) {
    return data.to;
  },
  getdepName(data) {
    console.log(data, "getdepcode1");
    return data.split("(")[0];
  },
  getdepName1(data) {
    console.log(data, "getdepcode1");
    return data.from;
  },
  getarrcode(data) {
    return data[data.length - 1].Arrival.AirportCode;
  },
  getarrName(data) {
    return data.split("(")[0];
  },
  getarrName1(data) {
    return data.to;
  },
  getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },
    getAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },
    getDepAirportName($event) {
      return $event[0].Departure.AirportName;
    },
    getReturnDepAirName($event) {
      return $event[0].Arrival.AirportName;
    },

    getArrAirportName($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },

    getReturnArrAirName($event) {
      return $event[0].Arrival.AirportName;
    },

    getDepStops($event) {
      return $event.length - 1;
    },

    getReturnStops($event) {
      return $event.length - 1;
    },

    // getDep1($event) {
    //     return $event[0].Departure.AirportCode;
    // },

    getarrivaldata($event) {
      return $event[0].Arrival.AirportName;
    },
    getreturndata($event) {
      return $event[0].Arrival.AirportName;
    },
    getarrtime($event) {
      return $event[0].Arrival.Time;
    },
    getreturntime($event) {
      return $event[0].Arrival.Time;
    },
    get1arrtime($event) {
      // console.log($event,index,'$event')

      return $event[$event.length - 1].Arrival.AirportName;
    },
    get1returntime($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },

    get2arrtime($event) {
      return $event[$event.length - 1].Arrival.Time;
    },

    get2returntime($event) {
      return $event[$event.length - 1].Arrival.Time;
    },
    getArrivaldate($event) {
      return moment($event[$event.length - 1].Arrival.Date).format("MMM DD");
    },

    getArrivalCode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getreturnname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getreturnNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },
    getreturnid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },
    getreturnDate($event) {
      return moment($event[0].Departure.Date).format("MMM DD");
    },
    getdeparDate($event) {
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },

    getdated($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated1($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated2($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("ddd, MMM DD, YYYY");
    },
    getreturnAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },
    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getreturnTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getreturnArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },
    getreturnArrivaldate($event) {
      return moment($event[$event.length - 1].Arrival.Date).format("MMM DD");
    },
    getreturnArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
  
    getlogo($event) {
      return $event[0].MarketingCarrier.logo;
    },
    getlogo1($event) {
      return $event[0].MarketingCarrier.logo;
    },
    getname($event) {
      return $event[0].MarketingCarrier.Name;
    },
  continuePayment(){
    let locales_id = this.local_id || this.Localoneway_id;
    if(locales_id.includes("multi")){
        this.$router.push({
            path: "/seatmap",
            query: { pass_uid: this.local_id },
        });
    }
    console.log(locales_id,'locales_iddd')
    this.$router.push({
      path: "/confirmBook",
      query: { [this.routerData]: locales_id }
    });
  },
  getDate($event) {
      return moment($event).format("MMM DD");
    },
  getdatedFlight($event) {
    return moment($event).format("MMM DD");
  },
  getdatedFlightMulti($event) {
    return moment($event[0].Date).format("MMM DD");
  },
  getdatedFlight1($event) {
    return moment($event).format("MMM DD");
  },
  getflightdep(data) {
    return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
  },
  getflightarr(data) {
    return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
  },
  depfligtTime(data) {
      // console.log(data, 'ppppppppp')
      let $event = data;
      let layoverTime = [];
      for (let i = 0; i <= $event.length - 1; i++) {
        // console.log($event[i], 'data.length - 1')

        const time1 = new Date(
          `${data[i].Arrival.Date}T${data[i].Arrival.Time}`
        );
        const time2 = new Date(
          `${data[i].Departure.Date}T${data[i].Departure.Time}`
        );

        const diffInMilliseconds = Math.abs(time2 - time1);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        layoverTime.push(
          `${hours.toString().padStart(2, "0")} h:${minutes
            .toString()
            .padStart(2, "0")} min`
        );
      }
      // console.log(layoverTime, 'layoverTimelayoverTimelayoverTimelayoverTime')
      let totalMinutes = layoverTime.reduce((total, time) => {
        let [hours, minutes] = time.match(/\d+/g).map(Number);
        return total + (hours * 60 + minutes);
      }, 0);

      let totalHours = Math.floor(totalMinutes / 60);
      let remainingMinutes = totalMinutes % 60;

      let combinedLayoverTime = `${totalHours
        .toString()
        .padStart(2, "0")} h:${remainingMinutes
        .toString()
        .padStart(2, "0")} min`;
      return combinedLayoverTime;
    },

    retfligtTime(data) {
      // console.log(data, 'ppppppppp')
      let $event = data;
      let layoverTime = [];
      for (let i = 0; i <= $event.length - 1; i++) {
        // console.log($event[i], 'data.length - 1')

        // const currentIndex = i;
        // const nextIndex = (i + 1) % data.length;

        const time1 = new Date(
          `${data[i].Arrival.Date}T${data[i].Arrival.Time}`
        );
        const time2 = new Date(
          `${data[i].Departure.Date}T${data[i].Departure.Time}`
        );

        const diffInMilliseconds = Math.abs(time2 - time1);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        layoverTime.push(
          `${hours.toString().padStart(2, "0")} h:${minutes
            .toString()
            .padStart(2, "0")} min`
        );
      }
      let totalMinutes = layoverTime.reduce((total, time) => {
        let [hours, minutes] = time.match(/\d+/g).map(Number);
        return total + (hours * 60 + minutes);
      }, 0);

      let totalHours = Math.floor(totalMinutes / 60);
      let remainingMinutes = totalMinutes % 60;

      let combinedLayoverTime = `${totalHours
        .toString()
        .padStart(2, "0")} h:${remainingMinutes
        .toString()
        .padStart(2, "0")} min`;
      return combinedLayoverTime;
    },

  confirmSelection() {
      // if (this.nextFlightCallback) {
      //   this.nextFlightCallback();
      // }
      this.dialog = false;
    },
    cancelSelection() {
      this.dialog = false;
    },
  getperpersonPrice(getdata) {
      if (getdata.adultcount) {
        let getAdtperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getAdtperfare, "getadultTotBasegetadultTotBase...2");
        return getAdtperfare.toFixed(2);
      }

      if (getdata.childcount) {
        let getChdperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getChdperfare, "getadultTotBasegetadultTotBase...3");
        return getChdperfare.toFixed(2);
      }

      if (getdata.infcount) {
        let getInfperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getInfperfare, "getadultTotBasegetadultTotBase...4");
        return getInfperfare.toFixed(2);
      }
    },
    passengerDetail() {
      this.passDeatail = JSON.parse(sessionStorage.getItem("TravelerDetails"));
      console.log(this.adult, "this.adult this.adult ");

      for (let i = 0; i < this.passDeatail.adultDatas.length; i++) {
        if (i < this.adult) {
          this.adultDetailData.push({
            lastname: "",
            firstname: "",
            middlename: null,
            salutation: "",
            gender: "",
            dob: "",
            pax_type: "Adult",
            seat_requested:[]
          });
        }
      }

      this.adultDetailData.forEach((a, i) => {
        this.passDeatail.adultDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
            a.seat_requested = v.seat_requested;
          }
        });
      });

      // console.log(this.adultDetailData,'this.adultDetailDatathis.adultDetailData')

      for (let i = 0; i < this.passDeatail.childDatas.length; i++) {
        if (i < this.child) {
          this.childDetailData.push({
            lastname: "",
            firstname: "",
            middlename: null,
            salutation: "",
            gender: "",
            dob: "",
            pax_type: "Child",
            seat_requested:[]
          });
        }
      }

      this.childDetailData.forEach((a, i) => {
        this.passDeatail.childDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
            a.seat_requested = v.seat_requested;
          }
        });
      });

      for (let i = 0; i < this.passDeatail.infrantDatas.length; i++) {
        if (i < this.inf) {
          this.infDetailData.push({
            lastname: "",
            firstname: "",
            middlename: null,
            salutation: "",
            gender: "",
            dob: "",
            pax_type: "Infant",
            seat_requested:[]
          });
        }
      }

      this.infDetailData.forEach((a, i) => {
        this.passDeatail.infrantDatas.forEach((v, j) => {
          if (i == j) {
            a.lastname = v.last;
            a.firstname = v.fname;
            a.middlename = v.middle;
            a.salutation = v.salutation;
            a.gender = v.gender;
            a.dob = moment(v.dateOfBirth).format("DD-MMM-YYYY");
            a.seat_requested = v.seat_requested;
          }
        });
      });

      this.totalPassData = [
        ...this.adultDetailData,
        ...this.childDetailData,
        ...this.infDetailData,
      ];

      this.totalPassData.forEach((c) => {
        c.fareup = true;
      });

      console.log(this.totalPassData, "this.totalPassDatathis.totalPassData");
    },
  async getFareRules() {
       window.scrollTo(0, 0);
      let fareRequest = {
        request_type: "farerules",
        shopping_response_id: this.shopId,
        offer_id: this.offId,
        portal_id: this.portalId,
      };
      console.log(fareRequest, "fareRequestssssss");
      await axios
        .get(this.fareRulesApi, {
          headers: {
            "Content-Type": "text/html",
          },
          params: fareRequest,
        })
        .then((response) => {
          this.fareRulesContent = response.data;
          // console.log(response.data, "fareRulesContentfareRulesContent.....")
        })

        .catch((error) => {
          console.log(error, "erroroor.....");
        });
    },

  getConfig() {
    const getConfigData1 = getConfigDataFromLocalStorage();
    if (getConfigData1) {  
      this.seatmapApi = getConfigData1.payload.portal_configuration.API_endpoints.seatmap;
      console.log(this.seatmapApi,'this.seatmapApi');
        this.displayCurrency = getConfigData1.payload.portal_configuration.currency.display;
        this.metaCurrency = getConfigData1.payload.portal_configuration.currency.iso_code;
        this.portal_id = getConfigData1.payload.portal_configuration.portal_id;
        this.pointScale = getConfigData1.payload.portal_configuration.pointofsale;
        this.airLogoApi = getConfigData1.payload.portal_configuration.content_data.airlines_logo;
        this.fareRulesApi = getConfigData1.payload.portal_configuration.API_endpoints.farerules;
    }
  }, 

  async  getseatMapping() {
    this.dataLoder=true
      let seat = {
        request_type: "seatmap",
        offer_id: "",
        shopping_response_id: "",
        hit_id: "",
        search_cabin_type: "",
        trip_type: "",
        pointofsale:"",
        adult: "",
        child: "",
        paxInfant: "",
      };
    console.log(this.getTotalData,'this.getTotalData')
    
      seat.shopping_response_id =this.getTotalData.ShoppingResponseId  ;
      seat.offer_id =this.getTotalData.selectId;
      seat.hit_id =this.getTotalData.hitId;
      seat.search_cabin_type =this.getTotalData.cabinClass;
      seat.pointofsale =this.pointScale;
      seat.adult =this.getTotalData.Adult;
      seat.child =this.getTotalData.Child || 0;
      seat.paxInfant =this.getTotalData.Inf || 0;
      seat.trip_type =this.getTotalData.tripType;

      let obj = {
        adult: this.getTotalData.Adult,
        chd: this.getTotalData.Child || 0,
        inf: this.getTotalData.Inf || 0
      }

      console.log(seat,this.seatmapApi,obj,'4567')

    await axios.post(this.seatmapApi, seat, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((responce) => {
      // console.log(responce,this.seatSelect, "seatmapdata223");
      this.seatSelect = responce.data.data;
      console.log(this.seatSelect,'this.seatSelect')
      this.checkSeatmap(this.seatSelect);
      this.getseatselect();
      this.seatPassanger(obj);
      this.fetchTravelerData();
      this.pageloader=false;
          
    })
    .catch((err) => {
      this.pageloader=false;
      console.log(err, "ppppp52222");
      this.seatmapError = true;
    });

      // console.log(seat, "kkkk3333333333");
  },
  // totalpassData(data, index) {
  //   console.log(data, index, "data, index");
  //   // this.flightSelect=0
  //   this.passSelect = index;
      
  // },
  checkSeatmap(flightData) {
    const allNoCabins = flightData.every(segment => 
      segment.every(flight => !flight.cabins || flight.cabins.length === 0)
    );
    console.log(allNoCabins,'allNoCabinsallNoCabins')

    this.seatmapError = allNoCabins;
  },

getseatselect() {
  let $event = [];
  this.seatSelect.forEach((v, i) => {
    // console.log(v, i, "keerthikannan123");
    this.seatSelect[i].forEach((c) => {
      // console.log(c,c.cabins.length, "vasanthaganesh");
      $event.push(c);
//           if (c && Array.isArray(c.cabins) && c.cabins.length > 0) {
//   $event.push(c);
// }

    });
  });
  this.totalSeat = $event;

  if (this.totalSeat.length > 0) {
   if(this.totalSeat[0].cabins && this.totalSeat[0].cabins.length > 0){
    this.totalSeat[0].cabins.forEach((c) => {
      // console.log(c, "ppppppppppppppppp");
      let arrayData = [];
      arrayData = c.columns;


      let data = 0;
      arrayData.forEach((a, l) => {
        console.log(a, "rowsrows");
        if (a.type == "AisleA") {
          data = data + 1;
          if (data == 2) {
            arrayData[l - 1].space = true;
            arrayData[l].space = false;
          }
        } else {
          data = 0;
          arrayData[l].space = false;
        }
        c.seat_rows.forEach((d) => {
          // console.log(d,a,'000000')
          if (d.seats[0].seat_number == a.column) {
            // console.log(this.totalData , d,a.column,'lololol')

            if (!this.totalData[a.column]) {
              this.totalData[a.column] = [];
            }

            this.totalData[a.column].push(d);
          }
        });
        this.totalData[a.column].forEach((w) => {
          w.color = false;
          // console.log(w, "dddwwwwdddd");
        });
       
      });
      console.log(arrayData, "arrayData");
    });
   
    console.log(this.totalData, "$data$data$data");
    this.pageloader=false
  }
  else{
    // this.dialog2 = true;
  }
  }
},
// checkFlightSegment() {
//   let allHaveFlightSelect = this.totalpassanger.forEach((passenger) => {
//     let seatNumber = passenger.seat_number;

//     if (!Array.isArray(seatNumber) && seatNumber) {
//       seatNumber = [...seatNumber];
//     }
//     let hasFlightSelect = seatNumber.length-1  == this.flightSelect;

//     console.log(seatNumber, this.flightSelect, hasFlightSelect, "Checking passenger...");
//     // return hasFlightSelect;
//   });

//   return allHaveFlightSelect;
// },
checkFlightSegment() {
  let anyPassengerWithoutSeat = this.totalpassanger.some((passenger) => {
    let seatNumber = passenger.seat_number;
    console.log(passenger,'passengerrr')

    if (!Array.isArray(seatNumber)) {
      seatNumber = seatNumber ? [seatNumber] : [];
    }
    if(passenger.type == "Infrant" || passenger.type == "Infant"){
      return false;
    }

    if (!this.totalSeat[this.indexData] || 
        !this.totalSeat[this.indexData].cabins || 
        !this.totalSeat[this.indexData].cabins[0] || 
        !this.totalSeat[this.indexData].cabins[0].columns) {
      console.log("Cabins or columns missing, returning false...");
      return true;
    }

    let hasFlightSelect = seatNumber.some((_, index) => {
      console.log(index,index === this.flightSelect,'checking passenger...1')
      return index === this.flightSelect
    });

    console.log(seatNumber, this.flightSelect, hasFlightSelect, "Checking passenger...");

    return !hasFlightSelect;
  });

  if (anyPassengerWithoutSeat) {
    console.log("Not all passengers have selected seats. Displaying v-dialog...");
    return false;
  }

  console.log("All passengers have selected seats. Moving to the next flight...");
  return true;
},

 finalizeSeats(flightKey) {
    this.flightSelectionStatus[flightKey] = true;
    console.log(`Seat selection finalized for Flight ${flightKey}. No further changes allowed.`);
    // this.totalAmountpassenger = parseFloat(Number(this.totalAmountpassenger) + Number(this.totalSeatPrice)).toFixed(2);
    // console.log(this.totalAmountpassenger,this.totalSeatPrice,'totalAmounttt')
    // this.seatCharge = true;
  },

selectdata(data, index) {
  // console.log(data, index,'000000');
    // console.log(data.cabins, data.cabins.length === 0,'000000...1');
  if (!data || !data.cabins || data.cabins.length === 0) {
    console.log('datasssTrue...1')
    this.dialog2 = true;
    this.flightSelect=index
    this.passSelect = 0;
    this.indexData = index;
    this.singlePass = false;
    if (this.flightSelect == this.seatSelect.length-1) {
      this.addTotalPrice();
    }
    return
  }

   if (this.flightSelectionStatus && this.flightSelectionStatus[index]) {
    console.log('datasssTrue...2')
    console.log(`Flight ${index} is already selected.`);
    this.flightSelect = index;
    this.indexData = index;
    this.passSelect = this.passengerCount;
    console.log(this.passSelect, this.passengerCount,'datasssTrue....6')
  // this.singlePass = false;
  this.totalData = [];
  let falData = false;
  if (this.totalSeat.length > 0) {
    this.totalSeat[index].cabins.forEach((c, k) => {
      c.seat_rows[k].color = falData[k];

      let arrayData = [];
      arrayData = c.columns;
      let data = 0;
      arrayData.forEach((a, l) => {
        if (a.type == "AisleA") {
          data = data + 1;
          if (data == 2) {
            arrayData[l - 1].space = true;
            arrayData[l].space = false;
          }
        } else {
          data = 0;
          arrayData[l].space = false;
        }

        c.seat_rows.forEach((d) => {
          // console.log(d, "rowsrows");
          if (d.seats[d.seats.length-1].seat_number == a.column) {
            if (!this.totalData[a.column]) {
              this.totalData[a.column] = [];
            }
            this.totalData[a.column].push(d);
          }
        });

        // this.totalData[a.column].forEach((w) => {
        //   console.log(w, "dddwwwwdddd");
        // });
      });
    });
  }
  this.handleSeatmap(false)
  }
  else {
    let data = this.checkFlightSegment()
    if(!data && this.totalSeat[this.indexData] && this.totalSeat[this.indexData].cabins 
    && this.totalSeat[this.indexData].cabins.length > 0){
      console.log(data,'dataasss')
      this.dialog = true
    }
  else {
    console.log('datasssTrue...3')
    this.flightSelect=index
  this.passSelect = 0;
  this.indexData = index;
  this.singlePass = false;
  this.totalData = [];
  let falData = false;
  if (this.totalSeat.length > 0) {
    this.totalSeat[index].cabins.forEach((c, k) => {
      c.seat_rows[k].color = falData[k];

      let arrayData = [];
      arrayData = c.columns;
      let data = 0;
      arrayData.forEach((a, l) => {
        if (a.type == "AisleA") {
          data = data + 1;
          if (data == 2) {
            arrayData[l - 1].space = true;
            arrayData[l].space = false;
          }
        } else {
          data = 0;
          arrayData[l].space = false;
        }

        c.seat_rows.forEach((d) => {
          // console.log(d, "rowsrows");
          if (d.seats[d.seats.length-1].seat_number == a.column) {
            if (!this.totalData[a.column]) {
              this.totalData[a.column] = [];
            }
            this.totalData[a.column].push(d);
          }
        });

        // this.totalData[a.column].forEach((w) => {
        //   console.log(w, "dddwwwwdddd");
        // });
      });
    });
  }
  this.handleSeatmap(true)
  }
  }
},
addTotalPrice() {
  const data = this.checkFlightSegment()
  console.log(data,"totalAmountPassenger..1")
  if(data){
    let totalPrice = parseFloat(this.totalbasePassanger) + parseFloat(this.totalTaxPassanger) + this.totalSeatPrice;
    totalPrice = parseFloat(totalPrice.toFixed(2));
    this.totalAmountpassenger = totalPrice.toFixed(2)
    this.seatCharge = true;
    console.log(this.totalAmountpassenger,totalPrice, 'totalAmountPassenger');
  }
  else if(!data && this.passengerCount == 1){
    let totalPrice = parseFloat(this.totalbasePassanger) + parseFloat(this.totalTaxPassanger) + this.totalSeatPrice;
    totalPrice = parseFloat(totalPrice.toFixed(2));
    this.totalAmountpassenger = totalPrice.toFixed(2)
    this.seatCharge = true;
    console.log(this.totalAmountpassenger,totalPrice, 'totalAmountPassenger...2');
  }
},

  seatPassanger(obj) {
    console.log(obj,'objofseatPassenger')
    if(obj){
      const totalPassengers = Number(obj.adult || 0) + Number(obj.chd || 0) + Number(obj.inf || 0);
      console.log(totalPassengers, 'totalPassengers')
      this.totalpassanger = [];

      for (let i = 0; i < Number(obj.adult || 0); i++) {
        this.totalpassanger.push({ seat_number: [], type: 'Adult', index: i, name:''});
      }

      for (let i = 0; i < Number(obj.chd || 0); i++) {
        this.totalpassanger.push({ seat_number: [], type: 'Child', index: i, name:'' });
      }

      for (let i = 0; i < Number(obj.inf || 0); i++) {
        this.totalpassanger.push({ type: 'Infrant', index: i, name:'' });
          // index++;
      }
    }

    console.log(this.totalpassanger, this.totalSeat.length, "this.totalpassanger");
  },
    // totalSeatData() {
    //   let travelerData = JSON.parse(localStorage.getItem('TravelerDetails'))
    //   console.log(travelerData.adultDatas,'travelerDataaa')

    //   this.totalpassanger.forEach((c, i) => {
    //     console.log(c, i, 'datasss')
    //     if (c.type == "Adult") {
    //       travelerData.adultDatas[i].seat_requested = c.seat_number
    //       // c.name=
    //     }
    //     else if (c.type == "Child" && travelerData.childDatas[i]) {
    //       travelerData.childDatas[i].seat_requested = c.seat_number
    //     }
    //     else if (c.type == "Infrant" && travelerData.infrantDatas[i]) {
    //       travelerData.infrantDatas[i].seat_requested = c.seat_number
    //     }

    //   })

    //   let data = JSON.stringify(travelerData)
    //   localStorage.setItem('TravelerDetails',data)


    // },

  totalSeatData() {
    let travelerData = JSON.parse(sessionStorage.getItem('TravelerDetails')) || {
      adultDatas: [],
      childDatas: [],
      infrantDatas: []
    };

    console.log(travelerData, 'Initial travelerData');

    this.totalpassanger.forEach((passenger) => {
      console.log(passenger, 'Processing passenger');

      if (passenger.type === "Adult") {
        if (!travelerData.adultDatas[passenger.index]) {
          console.warn(`No adult data at index ${passenger.index}`);
          return;
        }
        if (!travelerData.adultDatas[passenger.index].seat_requested) {
          travelerData.adultDatas[passenger.index].seat_requested = [];
        }
        travelerData.adultDatas[passenger.index].seat_requested = passenger.seat_number;
      } else if (passenger.type === "Child") {
        if (!travelerData.childDatas[passenger.index]) {
          console.warn(`No child data at index ${passenger.index}`);
          return;
        }
        if (!travelerData.childDatas[passenger.index].seat_requested) {
          travelerData.childDatas[passenger.index].seat_requested = [];
        }
        travelerData.childDatas[passenger.index].seat_requested = passenger.seat_number;
      } else {
        console.warn(`Unknown passenger type: ${passenger.type}`);
      }
    });

    sessionStorage.setItem('TravelerDetails', JSON.stringify(travelerData));

    console.log(travelerData, 'Updated travelerData saved to localStorage');
  },
  // seatSelectdata(datas, index1, index) {
  //   console.log(this.passSelect,this.flightSelect,datas,'this.passSelect')

  //   if( datas.seats[0].occupied === 'false'){


  //     for (let key in this.totalData) {
  //       this.totalData[key].forEach(item => (item.color = false));
  //     }
  //     if (this.totalData[index] && this.totalData[index][index1]) {
  //       this.totalData[index][index1].color = true;
  //     }


  //     this.totalpassanger[this.passSelect].seat_number[this.flightSelect] =
  //       `${this.totalSeat[this.flightSelect].origin}-${this.totalSeat[this.flightSelect].destination}:${datas.row_number}${index}` || "";

  //     console.log(this.totalSeat,this.flightSelect,'totalSeattotalSeat')
  //     // if(this.totalSeat.length-1 != this.flightSelect){
  //       // this.flightSelect=this.flightSelect+1
  //       // this.selectdata(this.totalSeat[this.flightSelect], this.flightSelect)
  //       //this.nextFlightCallback = () => {
  //       //    this.flightSelect = this.flightSelect + 1;
  //       //    this.selectdata(this.totalSeat[this.flightSelect], this.flightSelect);
  //       //  };
  //       //  this.dialog = true;
  //     // }
  //   }

  // },

// seatSelectdata(datas, index1, columnIndex) {

//   console.log(datas,index1,columnIndex,'passengerData')
//   console.log(this.adult,this.child,this.inf,'this.adultthis.adult')
//   this.passengerCount = Number(this.adult) + Number(this.child);
  
//   if (this.passSelect >= this.passengerCount) {
//     console.log("Seat selection limit reached for this flight. Please select another flight manually.");
//     return;
//   }

//   if (datas.seats[0].occupied === "true") {
//     console.log("Seat is already occupied. Select a different seat.");
//     return;
//   }

//   console.log(this.passSelect,this.flightSelect,this.flightSelectionStatus,this.totalpassanger,'passengerData...1')
//  const passenger = this.totalpassanger[this.passSelect];
//  console.log(passenger,this.singlePass,this.passengerCount,'passengerssDataaa')

// if ((passenger && this.singlePass) || this.passengerCount == 1) {
//   console.log("Passenger Data:", passenger,this.singlePass);

//   if (passenger) {
//   console.log("Single Passenger Mode Active");

//   // For single passenger, always set passSelect to 0
//   if(this.passengerCount == 1){
//     this.passSelect = 0;
//   }

//   // Ensure seat_number is an array for the passenger
//   let seatNumber = Array.isArray(passenger?.seat_number)
//     ? passenger.seat_number
//     : [];
  
//   console.log(seatNumber, passenger, "seatNumber");

//   if (!Array.isArray(seatNumber) && passenger.seat_number) {
//     seatNumber = [...passenger.seat_number];
//   }

//   // Reset previously selected seat if any
//   if (seatNumber.length > 0) {
//     const firstSeat = seatNumber[this.flightSelect];
//     console.log("First Seat Number:", firstSeat);
//     if(firstSeat){
//     let { row, col } = this.extractSeatPosition(firstSeat);
//     if (row !== undefined && col !== undefined) {
//       col = col.replace(/"/g,'');
//       const previousSeat = this.totalData[col];
//       previousSeat.forEach((i) => {
//         if(i.row_number == Number(row)){
//           i.color = false;
//           i.selectedPass = null;
//           console.log(i,"First Seat Datasss")
//         }
//       })
//       console.log(row,col,previousSeat,this.totalData,"First Seat Number...1")
//     }
//   }else{
//     const selectedSeat = this.totalData[columnIndex][index1];
//     selectedSeat.color = true;
//     selectedSeat.selectedPass = this.passSelect + 1;
//     const seatDetails = `${this.totalSeat[this.flightSelect].origin}-${this.totalSeat[this.flightSelect].destination}:${datas.row_number}${columnIndex}`;
//     passenger.seat_number[this.flightSelect] = seatDetails;
//     // this.totalSeatPrice += Number(datas.seats[0].price);
//     // this.selectedFlights = {};
//      let flightId = this.flightSelect; // Assuming `flightSelect` holds the flight identifier
//     let seatPrice = Number(datas.seats[0].price);

//     // Only store valid prices (ignore '0.00' and '-')
//     if (seatPrice !== 0 && datas.seats[0].price !== '-') {
//         this.selectedFlights[flightId] = seatPrice;
//     } else {
//         this.selectedFlights[flightId] = 0.00; // Remove if invalid
//     }

//     // Calculate total seat price by summing all stored values
//     this.totalSeatPrice = Object.values(this.selectedFlights).reduce((acc, price) => acc + price, 0);
//     console.log(this.totalSeatPrice,this.selectedFlights,'totalSeatPrice...1')
//     this.finalizeSeats(this.flightSelect)
//   }
//   }

//   // Select the new seat
//   const selectedSeat = this.totalData[columnIndex]?.[index1];
//   if (selectedSeat) {
//     if (selectedSeat.selectedPass) {
//       console.log("Seat is already selected by another passenger.");
//       return;
//     }

//     selectedSeat.color = true;
//     selectedSeat.selectedPass = this.passSelect + 1;

//     // this.selectedFlights = {};
   
//     if(this.selectedFlights1 && Object.keys(this.selectedFlights1).length > 0){
//       let passengerId = this.passSelect;
//       let flightId = this.flightSelect;
//       let seatPrice = Number(datas.seats[0].price);
//       if (seatPrice === 0 || datas.seats[0].price === '-') {
//           this.selectedFlights1[passengerId][flightId] = 0.00;
//       } else {
//           this.selectedFlights1[passengerId][flightId] = seatPrice;
//       }
//       this.totalSeatPrice = Object.values(this.selectedFlights1).flatMap(flightObj => 
//         Object.values(flightObj)
//     ).reduce((acc, price) => acc + price, 0);

//     console.log(this.totalSeatPrice, this.selectedFlights1, 'totalSeatPrice...2');
//     }
//     else{
//       let flightId = this.flightSelect;
//       let seatPrice = Number(datas.seats[0].price);

//       if (seatPrice !== 0 && datas.seats[0].price !== '-') {
//           this.selectedFlights[flightId] = seatPrice;
//       } else {
//           this.selectedFlights[flightId] = 0.00;
//       }
//     this.totalSeatPrice = Object.values(this.selectedFlights).reduce((acc, price) => acc + price, 0);
//     console.log(this.totalSeatPrice,this.selectedFlights,'totalSeatPrice...')
//     }

//     const seatDetails = `${this.totalSeat[this.flightSelect].origin}-${this.totalSeat[this.flightSelect].destination}:${datas.row_number}${columnIndex}`;
//     passenger.seat_number[this.flightSelect] = seatDetails;
//     this.finalizeSeats(this.flightSelect)

//     console.log(`Passenger ${this.passSelect + 1} selected seat ${datas.row_number}${columnIndex}.`,this.totalpassanger);
//   } else {
//     console.log("Invalid seat selection. Please check the indices.");
//   }
// } else {
//   console.log("Not in single passenger mode.");

// }
// }
//  else if (this.totalData[columnIndex]?.[index1] && !this.flightSelectionStatus[this.flightSelect] && !this.singlePass) {
//     console.log('passengercolorTrueConditonnnnn')
//     const selectedSeat = this.totalData[columnIndex][index1];
//     selectedSeat.color = true;
//     selectedSeat.selectedPass = this.passSelect + 1;

//     const seatDetails = `${this.totalSeat[this.flightSelect].origin}-${this.totalSeat[this.flightSelect].destination}:${datas.row_number}${columnIndex}`;
//     this.totalpassanger[this.passSelect].seat_number[this.flightSelect] = seatDetails;

//     let passengerId = this.passSelect;
//     let flightId = this.flightSelect;
//     let seatPrice = Number(datas.seats[0].price);

//     if (!this.selectedFlights1[passengerId]) {
//         this.selectedFlights1[passengerId] = {};
//     }

//     if (seatPrice === 0 || datas.seats[0].price === '-') {
//       console.log('firstttoall...1')
//         this.selectedFlights1[passengerId][flightId] = 0.00;
//     } else {
//       console.log('firstttoall...2')
//         this.selectedFlights1[passengerId][flightId] = seatPrice;
//     }

//     this.totalSeatPrice = Object.values(this.selectedFlights1).flatMap(flightObj => 
//         Object.values(flightObj)
//     ).reduce((acc, price) => acc + price, 0);
//     console.log(this.totalSeatPrice,this.selectedFlights1,datas.seats[0].price,'totalSeatprice...2')

//     // console.log(`Passenger ${this.passSelect + 1} selected seat ${datas.row_number}${columnIndex}.`);

//     if (this.passSelect < this.passengerCount && !this.singlePass) {
//       this.passSelect++;
//     }

//     if (this.passSelect >= this.passengerCount) {
//       // console.log("All passengers have selected their seats.");
//       this.finalizeSeats(this.flightSelect)
//     }
//   } else {
//     console.log("Invalid seat selection. Please check the indices.");
//   }
// },

seatSelectdata(datas, index1, columnIndex) {
  console.log(datas, index1, columnIndex, 'passengerData');
  console.log(this.adult, this.child, this.inf, 'this.adultthis.adult');

  this.passengerCount = Number(this.adult) + Number(this.child);
  
  if (this.passSelect >= this.passengerCount) {
    console.log("Seat selection limit reached for this flight. Please select another flight manually.");
    return;
  }

  if (datas.seats[0].occupied === "true") {
    console.log("Seat is already occupied. Select a different seat.");
    return;
  }

  console.log(this.passSelect, this.flightSelect, this.flightSelectionStatus, this.totalpassanger, 'passengerData...1');
  const passenger = this.totalpassanger[this.passSelect];

  if ((passenger && this.singlePass) || this.passengerCount === 1) {
    this.handleSinglePassengerSelection(datas, index1, columnIndex, passenger);
  } else if (this.totalData[columnIndex]?.[index1] && !this.flightSelectionStatus[this.flightSelect] && !this.singlePass) {
    this.handleMultiPassengerSelection(datas, index1, columnIndex);
  } else {
    console.log("Invalid seat selection. Please check the indices.");
  }

},

handleSinglePassengerSelection(datas, index1, columnIndex, passenger) {
  console.log("Passenger Data:", passenger, this.singlePass);

  if (passenger) {
    console.log("Single Passenger Mode Active");

    if (this.passengerCount === 1) {
      this.passSelect = 0;
    }

    let seatNumber = Array.isArray(passenger?.seat_number) ? passenger.seat_number : [];

    if (!Array.isArray(seatNumber) && passenger.seat_number) {
      seatNumber = [...passenger.seat_number];
    }

    this.resetPreviousSeat(seatNumber);

    const selectedSeat = this.totalData[columnIndex]?.[index1];
    if (selectedSeat) {
      if (selectedSeat.selectedPass) {
        console.log("Seat is already selected by another passenger.");
        return;
      }

      this.updateSeatSelection(datas, selectedSeat);
      passenger.seat_number[this.flightSelect] = this.formatSeatDetails(datas, columnIndex);
      this.updateSeatPrice(datas);
      this.finalizeSeats(this.flightSelect);

      console.log(`Passenger ${this.passSelect + 1} selected seat ${datas.row_number}${columnIndex}.`, this.totalpassanger);
    } else {
      console.log("Invalid seat selection. Please check the indices.");
    }
    if (this.flightSelect == this.seatSelect.length-1) {
      this.addTotalPrice();
    }
  }
},

handleMultiPassengerSelection(datas, index1, columnIndex) {
  console.log('passengercolorTrueConditonnnnn');

  const selectedSeat = this.totalData[columnIndex][index1];
  selectedSeat.color = true;
  selectedSeat.selectedPass = this.passSelect + 1;

  this.totalpassanger[this.passSelect].seat_number[this.flightSelect] = this.formatSeatDetails(datas, columnIndex);

  this.updateSeatPrice(datas);

  if (this.passSelect < this.passengerCount && !this.singlePass) {
    this.passSelect++;
  }

  if (this.passSelect >= this.passengerCount) {
    this.finalizeSeats(this.flightSelect);
  }
   if (this.flightSelect == this.seatSelect.length-1) {
    this.addTotalPrice();
  }
},

resetPreviousSeat(seatNumber) {
  if (seatNumber.length > 0) {
    const firstSeat = seatNumber[this.flightSelect];
    console.log("First Seat Number:", firstSeat);
    if (firstSeat) {
      let { row, col } = this.extractSeatPosition(firstSeat);
      if (row !== undefined && col !== undefined) {
        col = col.replace(/"/g, '');
        const previousSeat = this.totalData[col];
        previousSeat.forEach(i => {
          if (i.row_number == Number(row)) {
            i.color = false;
            i.selectedPass = null;
            console.log(i, "First Seat Datasss");
          }
        });
      }
    }
  }
},

updateSeatSelection(datas, selectedSeat) {
  selectedSeat.color = true;
  selectedSeat.selectedPass = this.passSelect + 1;
},

formatSeatDetails(datas, columnIndex) {
  return `${this.totalSeat[this.flightSelect].origin}-${this.totalSeat[this.flightSelect].destination}:${datas.row_number}${columnIndex}`;
},

updateSeatPrice(datas) {
  let passengerId = this.passSelect;
  let flightId = this.flightSelect;
  let seatPrice = Number(datas.seats[0].price);

  if (!this.selectedFlights1[passengerId]) {
    this.selectedFlights1[passengerId] = {};
  }

  this.selectedFlights1[passengerId][flightId] = (seatPrice === 0 || datas.seats[0].price === '-') ? 0.00 : seatPrice;

  this.totalSeatPrice = Object.values(this.selectedFlights1)
    .flatMap(flightObj => Object.values(flightObj))
    .reduce((acc, price) => acc + price, 0);

  console.log(this.totalSeatPrice, this.selectedFlights1, datas.seats[0].price, 'totalSeatPrice...2');
},

extractSeatPosition(seatString) {
  const parts = seatString.split(":");
  const rowCol = parts[1];
  const row = rowCol.slice(0, -1);
  const col = rowCol.slice(-1);
  console.log(seatString,parts,rowCol,'seatStringggg',row,col)
  return {
    row: parseInt(row, 10),
    col: col
  };
},

// resetPreviousSeat(rowVal, colVal) {
//   console.log(this.totalpassanger,this.flightSelect,this.totalpassanger[this.passSelect],'Passenger Index');
  
//   if (this.totalpassanger[this.passSelect]) {
//     console.log("Passenger index matches flightSelect. Proceeding to reset...");

//    Object.keys(this.totalData).forEach((flightKey) => {
//      const flightSeats = this.totalData[flightKey];
      
//       flightSeats.forEach((row) => {
//         if (Number(row.row_number) === rowVal) {
//           const seat = row.seats[0].seat_number;
//           console.log(row,seat,"Passenger index matches flightSelect. Proceeding to reset...1");
//           if (seat == colVal) {
//             row.color = false;
//             row.selectedPass = null;
//             // this.flightSelectionStatus[this.flightSelect] = false;
//             console.log(`Seat reset in Flight ${flightKey}, Row ${rowVal}, Seat ${colVal}`);
//           } else {
//             console.log(`Seat not found at column ${colVal} in row ${rowVal}`);
//           }
//         }
//       });
//     });
//     return true;
//   } else {
//     console.log("Passenger index does not match flightSelect. No action taken.");
//     return false;  
//   }
// },

totalpassData(data, index) {
  console.log(data, index, "data, index");
  let tempPass = this.passSelect;
  console.log(tempPass,'passengerdataa--Temppp')
  this.passSelect = index;
  this.singlePass = true;
},

  fetchTravelerData() {
    const travelerData = JSON.parse(sessionStorage.getItem('TravelerDetails')) || {
      adultDatas: [],
      childDatas: [],
      infrantDatas: []
    };

    this.totalpassanger = travelerData.adultDatas.map((adult, index) => ({
      type: "Adult",
      index,
      name: `${adult.fname} ${adult.last || ''}`.trim(),
      seat_number: adult.seat_requested || []
    }));

    this.totalpassanger = this.totalpassanger.concat(
      travelerData.childDatas.map((child, index) => ({
        type: "Child",
        index,
        name: `${child.fname} ${child.last || ''}`.trim(),
        seat_number: child.seat_requested || []
      }))
    );

    this.totalpassanger = this.totalpassanger.concat(
      travelerData.infrantDatas.map((infant, index) => ({
        type: "Infrant",
        index,
        name: `${infant.fname} ${infant.last || ''}`.trim(),
      }))
    );

    console.log(this.totalpassanger, 'Processed totalpassanger');
    const hasSeatAssigned = this.totalpassanger.some(passenger => Array.isArray(passenger.seat_number) && passenger.seat_number.length > 0);
    console.log(hasSeatAssigned,'hasSeatAssigned')
      if(hasSeatAssigned){
        this.handleSeatmap(hasSeatAssigned)
      }
  },

  handleSeatmap(data){
     this.totalpassanger.forEach(passenger => {
      console.log(passenger,'updateSeatMap..41')
    if (passenger.seat_number && passenger.seat_number[this.flightSelect]) {
      let seat= passenger.seat_number[this.flightSelect]
        if (seat) {
          const seatDetails = seat.split(':')[1]; // Extract the seat details before the colon
          const match = seatDetails.match(/^(\d+)([A-Za-z]+)$/);

            console.log(seat,match,seatDetails,seat.split(':'), "updateSeatMap...1");
          if (match) {
            const row = match[1]; // Extract the row (digits)
            const column = match[2]; // Extract the column (letter)
            console.log(row,column)
            if(data){
              this.updateSeatMap(row, column, this.flightSelect, passenger);
            }
          } else {
            console.warn(`Invalid seat format: ${seatDetails}`); // Log invalid seat format
          }
        }
    }
  });
  },
  // updateSeatMap(row, column, flightIndex, passenger) {
  //   console.log(row, column, flightIndex, passenger,this.totalData,'updateSeatMap')
  //   if (this.totalData[column]) {
  //     console.log(this.totalData,this.totalData[column],'updateSeatMap..2')
  //     this.totalData[column].forEach((data) => {
  //       if(data.row_number == row){
  //         data.color = true;
  //         data.selectedPass = this.passSelect;
  //         console.log(data.selectedPass,this.passSelect,"updateSeatMap..3")
  //         if(this.passSelect < this.passengerCount){
  //           this.passSelect++
  //         }
  //       }
  //     })
  //   }
  // },

  async submitform() {
    this.totalSeatData();
    this.pageloader=true
    console.log(this.routerData,this.local_id,'routerData')

    let locales_id = this.local_id || this.Localoneway_id
    setTimeout(()=>{
      this.$router.push({
        path: "/confirmBook",
        query: { [this.routerData]: locales_id }
      });
    },3000)
    
  },
 validateSeatSelection() {
  const totalFlights = this.totalSeat.length;
  const totalPassengers = this.totalpassanger.length;

  for (let passengerIndex = 0; passengerIndex < totalPassengers; passengerIndex++) {
    const passenger = this.totalpassanger[passengerIndex];

    if (passenger.type === "Infrant") {
      console.log("Skipping seat selection check for infant...");
      continue;
    }

    for (let flightIndex = 0; flightIndex < totalFlights; flightIndex++) {
      const hasCabins = this.totalSeat[flightIndex]?.cabins?.length > 0;
      console.log(this.totalSeat,'seatNumberrr...1')

      if (!hasCabins) {
        console.log(`Skipping seat validation for flight ${flightIndex} as it has no cabins.`);
        continue;
      }

      const seatNumber = passenger?.seat_number[flightIndex];
      console.log(seatNumber,hasCabins, 'seatNumberrr');
      
      if (!seatNumber) {
        this.dialog = true;
        return false;
      }
    }
  }

  return true;
},

onContinue() {
  if (this.validateSeatSelection()) {
    this.totalSeatData();
    console.log("All seats selected. Proceeding to the next page.");
    let locales_id = this.local_id || this.Localoneway_id
    this.$router.push({
        path: "/confirmBook",
        query: { [this.routerData]: locales_id }
      });
  } else {
    console.log("Seat selection incomplete. Staying on the current page.");
  }
},
  handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        this.topSticky = "5%";
      } else {
        this.scrolled = false;
        this.topSticky = "23%";
      }

      this.lastPosition = window.scrollY;
    },
    getData() {
      let localData = JSON.parse(localStorage.getItem("rountData"));
      this.fromdate = moment(localData.dedate).format("ddd, MMM D, YYYY");
      this.todate = moment(localData.redate).format("ddd, MMM D, YYYY");
      this.city = localData.city;

      if (localData.from) {
        this.from = localData.from.split(" ");
        this.from.forEach((v, i) => {
          if (i !== this.from.length - 1) {
            this.fromname = v;
            console.log(v, this.fromname, "tdtdtddtdttdd......");
          }
        });
        this.fromcode = this.from[this.from.length - 1]
          .replace("(", "")
          .replace(")", "");
      }

      if (localData.to) {
        this.to = localData.to.split(" ");
        this.to.forEach((v, i) => {
          if (i !== this.to.length - 1) {
            this.toname = v;
            console.log(v, this.toname, "ttsdtsdyusdsdsjd.....");
          }
        });

        this.tocode = this.to[this.to.length - 1]
          .replace("(", "")
          .replace(")", "");
      }

      // ------------->Check this Function<--------------
      if (localData.from.name) {
        this.from = localData.from.name.split(" ");
        this.from.forEach((v, i) => {
          if (i !== this.from.length - 1) {
            this.fromname += v;
          }
        });

        this.fromcode = this.from[this.from.length - 1]
          .replace("(", "")
          .replace(")", "");
      }

      if (localData.to.name) {
        this.to = localData.to.name.split(" ");
        this.tocode = this.to[this.to.length - 1]
          .replace("(", "")
          .replace(")", "");
      } else {
        this.to = localData.to.split(" ");
        this.tocode = this.to[this.to.length - 1]
          .replace("(", "")
          .replace(")", "");
      }
      // -------------><--------------

      // console.log(this.fromcode, " this.from");
      // console.log(this.tocode, "  this.to");
      // console.log(localData, "localData");
    },

    getadult() {
      this.getData();

      this.roundresult.forEach((v) => {
        console.log(v, "ddsdjvsdb");
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infantLength = v.Inf;
      });
    },

    onewayData() {
      this.getData();
      this.onewayresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infantLength = v.Inf;
      });
    },

    getprice1() {
      let priceData = [];
      let rount = JSON.parse(localStorage.getItem("itineryData"));
      let oneway = JSON.parse(localStorage.getItem("onewayItineyData"));
      if (rount) {
        priceData = rount;
      }
      if (oneway) {
        priceData = oneway;
      }
      this.basePrice = priceData.price;
      this.taxPrice = priceData.Taxprice;
      // console.log(priceData, "priceData");
    },

    getprice(res) {
      return (res.price + res.Taxprice).toFixed(2);
    },
    multiData() {
      let res = this.local_id.split("-");
      let res1 = res[res.length - 1];
      let data_pass = [];
      data_pass = JSON.parse(localStorage.getItem(`${"multi"}-${res1}`));
      console.log(data_pass,res1, "data_pass");

      this.adultLength = data_pass.Adult;
      this.childLength = data_pass.Child;
      this.infLength = data_pass.Inf;
      this.adult = data_pass.Adult;
      this.child = data_pass.Child;
      this.inf = data_pass.Inf;

      let totalpass = [];
      totalpass = this.adultLength + this.childLength + this.infLength;
      console.log(totalpass, "totalpass");

      this.multiresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
      });
    },

     areLogosequal(data) {
      for (let i = 0; i < data.length; i++) {
        if (!this.sameLogoData.includes(data[i].MarketingCarrier.logo)) {
          this.sameLogoData.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoData,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoData
    },
    areLogosequal1(data) {
      for (let i = 0; i < data.length; i++) {
        if (!this.sameLogoData1.includes(data[i].MarketingCarrier.logo)) {
          this.sameLogoData1.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoData1,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoData1
    },
    areLogosequalMulti(data) {
      // this.sameLogoDataMulti = []
      // console.log(this.sameLogoDataMulti,'sameLogoDataMultisameLogoDataMulti')

      for (let i = 0; i < data.length; i++) {
        if (!this.sameLogoDataMulti.includes(data[i].MarketingCarrier.logo)) {
          this.sameLogoDataMulti.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoDataMulti,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoDataMulti
    },
    areLogosequalMulti1(data) {
      for (let i = 0; i < data.length; i++) {
        if (!this.sameLogoDataMulti1.includes(data[i].MarketingCarrier.logo)) {
          this.sameLogoDataMulti1.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoDataMulti1,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoDataMulti1
    },
    areLogosequalMulti2(data) {
      for (let i = 0; i < data.length; i++) {
        if (!this.sameLogoDataMulti2.includes(data[i].MarketingCarrier.logo)) {
          this.sameLogoDataMulti2.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoDataMulti2,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoDataMulti2
    },
    areLogosequalMulti3(data) {
      for (let i = 0; i < data.length; i++) {
        if (!this.sameLogoDataMulti3.includes(data[i].MarketingCarrier.logo)) {
          this.sameLogoDataMulti3.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoDataMulti3,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoDataMulti3
    },
    getsegment() {
      if (this.roundresult) {
        this.roundresult.forEach((v) => {
          v.Depature.forEach((s) => {
            this.segmentList.push(s);
          });
          if (v.Return) {
            v.Return.forEach((e) => {
              this.segmentList.push(e);
            });
          }
        });
        console.log(this.segmentList, "this.segmentListthis.segmentList...1.");
      }

      if (this.onewayresult) {
        this.onewayresult.forEach((v) => {
          v.Depature.forEach((s) => {
            this.segmentList.push(s);
          });
        });
        console.log(this.onewayresult, "this.segmentListthis.segmentList...2.");
      }


      if (this.multiresult) {
        this.multiresult.forEach((v) => {
          if (v.Flight1) {
            v.Flight1.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight2) {
            v.Flight2.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight3) {
            v.Flight3.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight4) {
            v.Flight4.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight5) {
            v.Flight5.forEach((s) => {
              this.segmentList.push(s);
            });
          }
        });
        console.log(this.segmentList, "this.segmentListthis.segmentList...3.");
      }
    },

},
watch: {
  totalSeat: {
    deep: true,
    handler(newVal) {
      const allNoCabins = newVal.every(flight =>
        !flight || !flight.cabins || flight.cabins.length === 0
      );

      this.seatmapError = allNoCabins;  

      if (this.seatmapError) {
        this.dialog2 = false;
      } else {
        if (!newVal[this.indexData] || !newVal[this.indexData].cabins || newVal[this.indexData].cabins.length === 0) {
          this.dialog2 = true;
          console.log("Dialog2 triggered for missing cabins in the current flight.");
        } else {
          this.dialog2 = false;
        }
      }
      console.log("Seatmap Error:", this.seatmapError);
    }
  }
},


computed:{
  getDataResult() {
    if (this.roundresult && this.roundresult.length > 0) {
      return this.roundresult;
    } else if (this.onewayresult && this.onewayresult.length > 0) {
      return this.onewayresult;
    } else if (this.multiresult && this.multiresult.length > 0) {
      return this.multiresult;
    }

    return [];
  },
},

mounted(){
  this.passengerDetail();
},

created(){
  this.getroutequery = this.$route.query.pass_id1;
  console.log(this.getroutequery,'getroutequerygetroutequery');
    this.totalpassanger= JSON.parse(localStorage.getItem("totalpassanger"))
    if(this.$route.query.pass_id){
        this.local_id = this.$route.query.pass_id 
        this.routerData="pass_id"
    }
    else if(this.$route.query.pass_id1){
        this.Localoneway_id=this.$route.query.pass_id1;
        this.routerData="pass_id1"
    }
    else if(this.$route.query.pass_uid1){
        this.local_id =this.$route.query.pass_uid1;
        this.routerData="pass_uid1"
    }
    else if(this.$route.query.pass_uid){
        this.local_id =this.$route.query.pass_uid;
        this.routerData="pass_uid"
    }
    
  
    console.log(this.totalpassanger,this.local_id,this.routerData,this.getTotalData,'this.totalpassanger')
    this.getConfig();
   

    // let $data = localStorage.getItem("delete_Id");

    // if ($data) {
    //   this.Localoneway_id = this.$route.query.pass_id1;
    //   this.local_id = this.$route.query.pass_id;
    // }

    // let $data = localStorage.getItem("loader")
    // if ($data == "true") {
    //   this.$router.push('/')
    // }

    let $paxcontact = localStorage.getItem("passengerContact");
    // console.log($paxcontact, '$paxcontact')
    this.paxcontact = JSON.parse($paxcontact);
    // console.log(this.paxcontact[0].number, "ppppp");

    this.local_id = this.$route.query.pass_id || this.$route.query.pass_uid;
    this.Localoneway_id = this.$route.query.pass_id1 || this.$route.query.pass_uid1;

    console.log(
      this.Localoneway_id,
      "this.local_id this.local_idthis.Localoneway_id "
    );
    console.log(this.local_id, "this.local_id env ");
    let locales_id = this.local_id || this.Localoneway_id;
     this.getTotalData = JSON.parse(
        localStorage.getItem(`${locales_id}`)
      );  
    this.getConfig();

    if (this.Localoneway_id) {
      let onewaydata_uid = [];
      onewaydata_uid = JSON.parse(
        localStorage.getItem(`${this.Localoneway_id}`)
      );
      console.log(onewaydata_uid, 'uid')

      if (onewaydata_uid) {
        this.roundresult.push(onewaydata_uid);
        // this.pageloader = this.roundresult ? false : true;
        this.getadult();
        this.itineraryInfo1 = true;
        this.pageloader = false;

        console.log(this.roundresult, "onewayresult.....onewayresult");

        setTimeout(() => {
          this.getsegment();
        }, 2000);
      } else if (this.Localoneway_id !== onewaydata_uid) {
        console.log('onewayData')
        setTimeout(() => {
          this.pageloader = true;
          this.dialog2 = true;
        }, 5000);
      }
    } 
    else if (this.local_id) {
      if (this.local_id.includes("multi")) {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));
        if (data_uid) {
          this.multiresult.push(data_uid);
          // this.pageloader = this.multiresult ? false : true;
          this.multiData();
          this.pageloader = false;
          console.log(data_uid, "multicitymulticitymulticity");
          setTimeout(() => {
            this.getsegment();
          }, 2000);
        }
      } else {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));

        if (data_uid) {
          this.roundresult.push(data_uid);
          // this.pageloader = this.roundresult ? false : true;
          this.getadult();
          this.itineraryInfo = true;
          this.pageloader = false;

          console.log(this.roundresult, "roundresult.....roundresult");

          setTimeout(() => {
            this.getsegment();
          }, 2000);
        } else if (this.local_id !== data_uid) {
          console.log("dialog1dialog1dialog2");

          setTimeout(() => {
            // this.pageloader = true;
            this.dialog2 = true;
          }, 5000);
        }
      }
    this.seatPassanger();

    } else {
      // console.log('redirectingggg...')
      //   this.$router.push("/");
      // setTimeout(() => {
      //   console.log('dialog1dialog1dialog2')
      //         this.pageloader = true;
      //         this.dialog1 = true;
      //       }, 5000);
    }
     this.getseatMapping();
    if (this.roundresult.length > 0) {
      this.shoppingid = this.roundresult[0].ShoppingResponseId;
      this.selectId = this.roundresult[0].selectId;
    } else if (this.onewayresult.length > 0) {
      this.shoppingid = this.onewayresult[0].ShoppingResponseId;
      this.selectId = this.onewayresult[0].selectId;
    } else if (this.multiresult.length > 0) {
      this.shoppingid = this.multiresult[0].ShoppingResponseId;
      this.selectId = this.multiresult[0].selectId;
    }
}

}

</script>

<style scoped>

.true-sheet {
  border: 2px solid green;
  border-radius: 5px;
  padding: 1px 2px;
  cursor: pointer;
  background: green;
}
.true-sheet:focus {
  background: green !important;
  border: 2px solid green !important;
}
.false-sheet {
  border: 2px solid #e2e2e2;
  border-radius: 5px;
  background: #e2e2e2;
  padding: 1px 2px;
}
.tru-icon {
  background: rgb(250, 164, 31) !important;
  border: 2px solid rgb(250, 164, 31) !important;
}
.tru-pass {
  background: rgb(250, 164, 31) !important;
  color: white !important;
  /* font-size:13px; */
}
.tru-flight {
  background: rgb(250, 164, 31) !important;
  color: white !important;
  padding: 5px;
}
.tru-flightfalse {
  padding: 5px;
  border: 1px solid gray;
}
.av-sheet {
  background: green;
  width: 17px;
  height: 17px;
  border: 2px solid green;
  margin: 3px;
  border-radius: 3px;
}
.sel-sheet {
  background: rgb(250, 164, 31);
  width: 17px;
  height: 17px;
  border: 2px solid rgb(250, 164, 31);
  margin: 3px;
  border-radius: 3px;
}
.oc-sheet {
  background: lightgray;
  width: 17px;
  height: 17px;
  border: 2px solid lightgray;
  margin: 3px;
  border-radius: 3px;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  /* border: 1px solid lightgray; */
  /* table-layout: fixed; */
}
td,
th {
  text-align: left;
  padding: 8px;
  word-wrap: break-word;
}
.fare-summery {
  position: sticky;
  width: 100%;
  z-index: 1;
  transition: top 0.5s;
}
.drawer-section .text-reset:hover {
  color: red !important;
  background: white;
  border-radius: 8px;
  height:18px;
}

:deep(.drawer-section .v-slide-group__content) {
  display: flex;
  justify-content: space-evenly;
}

:deep(.drawer-section .v-slide-group__content .v-btn) {
  height: 45px !important;
}
:deep(.drawer-section .v-slide-group__content .v-btn__content) {
  font-size: 12px;
}

:deep(.drawer-section .v-slide-group__content) {
  background: #f3f1f1;
  height: 50px;
}

:deep(.drawer-section .v-slide-group__content .v-slide-group-item--active .v-btn__content) {
  color: rgb(250, 164, 31) !important;
}

:deep(.drawer-section .v-timeline-divider__inner-dot) {
  background: rgb(250, 164, 31) !important;
}

:deep(.drawer-section .v-timeline--vertical.v-timeline) {
  grid-row-gap: 24px;
}

:deep(.drawer-section .v-timeline--vertical.v-timeline) {
  /* grid-row-gap: 24px; */
  height: auto !important;
}
.paxpricesection td{
  padding:8px !important;
}
.selected{
  color:rgb(250, 164, 31);
}
@media screen and (max-width:480px) {
  .drawer-section .offcanvas {
    width: 100% !important;
  }
}
.drawer-section .offcanvas {
  width: 35%;
}
:deep(.drawer-section .v-timeline--vertical.v-timeline) {
  grid-row-gap: 24px !important;
}

:deep(.drawer-section .v-timeline) {
  justify-content: left !important;
}

:deep(.drawer-section .v-list-item__content) {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.offcanvas-header {
  background: #5293cb !important;
  color: white !important;
}

:deep(.btn-icon .v-btn--size-default) {
  --v-btn-height: 11px !important;
}

.drawer-section .text-reset:hover {
  color: red !important;
  background: white;
  border-radius: 8px;
}

:deep(.drawer-section .v-slide-group__content) {
  display: flex;
  justify-content: space-evenly;
}

:deep(.drawer-section .v-slide-group__content .v-btn ){
  height: 45px !important;
}

:deep(.drawer-section .v-slide-group__content .v-btn__content) {
  font-size: 12px;
}

:deep(.drawer-section .v-slide-group__content) {
  background: #f3f1f1;
  height: 50px;
}

:deep(.drawer-section .v-slide-group__content .v-slide-group-item--active .v-btn__content) {
  color: rgb(250, 164, 31) !important;
}

:deep(.drawer-section .v-timeline-divider__inner-dot) {
  background: rgb(250, 164, 31) !important;
}

:deep(.drawer-section .v-timeline--vertical.v-timeline) {
  grid-row-gap: 24px;
}

:deep(.drawer-section .v-timeline--vertical.v-timeline) {
  /* grid-row-gap: 24px; */
  height: auto !important;
}

.travel-btn {
  position: relative;
  background-color: rgb(250, 164, 31) !important;
  color: white !important;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s;
}

.travel-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #ffc104 0%);
  transition: left 0.3s;
}

.travel-btn:hover::before {
  left: 0;
}

.travel-btn:hover {
  color: black !important;
}

.form-check .form-check-input:checked {
  background-color: rgb(250, 164, 31) !important;
}

:deep(.cancellationRules .fareRules_content__1uPa7 .FareRule h2) {
  font-size: 17px !important;
  font-weight: 700 !important;
}

:deep(.cancellationRules .fareRules_content__1uPa7 .FareRule p) {
  overflow-wrap: break-word !important;
  inline-size: 58%;
}

:deep(.cancellationRules .fareRules_content__1uPa7 .FareRule ul li) {
  overflow-wrap: break-word !important;
  inline-size: 60%;
}

:deep(.drawer-section .v-slide-group__content) {
  display: flex;
  justify-content: center !important;
}

:deep(.v-slide-group__content .v-btn) {
  height: 45px !important;
}

:deep(.v-slide-group__content .v-btn__content) {
  font-size: 12px;
}

:deep(.drawer-section .v-slide-group__content) {
  background: #f3f1f1;
  height: 50px;
}

:deep(.drawer-section .v-slide-group__content .v-slide-group-item--active .v-btn__content) {
  color: rgb(250, 164, 31);
}

:deep(.v-tabs--density-default.v-tabs--stacked) {
  --v-tabs-height: 58px;
}

>>>.v-tabs--fixed-tabs .v-slide-group__content>*:last-child,
.v-tabs--align-tabs-center .v-slide-group__content>*:last-child {
  margin-inline-end: inherit;
}
</style>
